import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/**
 * Configure i18n
 *
 * TODO consider:
 * 1. Creating a type for translation resources to make sure that no
 * keys are missing in different languages.
 * 2. Loading the translation objects from JSON files instead.
 */
void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translation: {
          headerTitle: 'This is Croogloo',
          dashboard: {
            actions: {
              kits: 'Kit',
              sendSmsAlert: 'Send\nText Message',
              sendEmail: 'Send\nEmail',
              trackDistribution: 'Track\nDistribution',
              manageLists: 'Manage\nLists',
              generateSides: 'Generate Sides',
              uploadScripts: 'Upload\nScripts',
              productionForms: 'Production Forms',
              productionFiles: 'Production\nFiles',
              generateCrewList: 'Generate Crew List'
            },
            callTime: {
              helloUser: 'Hi, {{name}}!',
              callTime: 'Your call time is at {{time}}',
              date: ' on {{date}}'
            },
            DTR: {
              title: 'DTR',
              viewReport: 'View\nReport',
              launchDTR: 'Launch\nDTR'
            },
            foldersList: {
              latestDistro: 'Files',
              productionForms: 'Forms',
              distroCategories: {
                callsheet: 'Call\nSheet',
                script: 'Scripts',
                prepschedule: 'Prep\nSchedule',
                shooting: 'Shooting\nSchedule',
                crewList: 'Crew\nList',
                chrono: 'Chrono\nSchedule',
                oneliner: 'Oneliner\nSchedule',
                sides: 'Sides'
              },
              attach: {
                error: 'No file available to attach'
              },
              download: {
                error: 'No file available to download'
              }
            }
          },
          header: {
            myAccount: 'My Account',
            userSettings: 'User Settings',
            passwordSettings: 'Password Settings',
            productionSettings: 'Production Settings',
            addProduction: 'Add Production',
            switchProduction: 'Switch Production',
            manageSubscription: 'Manage Subscription',
            endSubscription: 'End Subscription',
            logout: 'Logout',
            notifications: 'Notifications',
            settings: 'Settings',
            help: 'Help',
            guest: 'Guest',
            addContact: 'Add Contact',
            googleImport: 'Google Contact Import',
            vCard: 'vCard',
            template: 'Template'
          },
          login: {
            username: 'Username',
            password: 'Password',
            validation: {
              invalidData: 'Invalid username or password, check that your email has been invited to Croogloo'
            },
            passwordRecovery: {
              heading: 'Password Recovery',
              content: 'Fill in your email address',
              invalidData: 'Invalid Email'
            },
            twoFactorAuth: {
              heading: 'Two-factor Authentication',
              content: 'Please enter the SMS Code sent to your mobile {{number}}',
              timeOut: 'Timeout!',
              error: {
                empty: 'Please enter the code sent to your mobile',
                server: 'Server Error. Contact support@croogloo.com for assistance.',
                code: 'Error with entered code. Please try again.'
              }
            }
          },
          userSettings: {
            tabs: {
              myAccount: 'My Account',
              userSettings: 'User Settings',
              prodSettings: 'Production Settings',
              passwordSettings: {
                title: 'Password Settings',
                currentPassword: 'Current Password',
                newPassword: 'New Password',
                newPasswordRoundTwo: 'Confirm New Password',
                showPassword: 'Show Password',
                hidePassword: 'Hide Password',
                savingPassword: 'Saving Password',
                saveSuccess: 'Password Successfully Saved'
              }
            },
            myAccount: {
              submit: 'Save',
              cancel: 'Cancel',
              edit: 'Edit',
              saveSignature: 'Save signature',
              success: 'Account data successfully saved.',
              error: 'Error saving account data.'
            },
            prodSettings: {
              messages: {
                cacheError: 'Error clearing cache',
                cacheCleared: 'Cache successfully cleared',
                saveSuccess: 'Production Settings Successfully Saved',
                saveError: 'Error saving Production Settings',
                headersReset: 'Headers successfully reset',
                headersError: 'Error reseting headers'
              },
              googleSync: {
                title: 'Google Sync',
                description: 'When importing Google Contacts. If contact(s) were deleted in Croogloo then:',
                options: {
                  addBack: 'Add back to contacts',
                  skip: 'Skip deleted contacts'
                }
              },
              emailDrafts: {
                title: 'Email Drafts',
                description: 'Email drafts can be shared across production administrators or private to your account',
                options: {
                  private: 'Private to user',
                  share: 'Share with admins'
                }
              },
              scriptationDeepLinks: {
                title: 'Scriptation Deep Link Attachments',
                description: 'Include files in email when sending files as Scriptation Deep Link',
                options: {
                  always: 'Always',
                  never: 'Never'
                }
              },
              sendReport: {
                title: 'Send Report',
                description: 'Distribute report listing recipients and message status should be emailed to:',
                options: {
                  sender: 'Sender',
                  replyTo: 'Reply-to address',
                  admins: 'All admins',
                  distroLists: 'Selected distribution lists'
                }
              },
              refreshCache: {
                title: 'Refresh Cache',
                description: 'Refresh cache to remedy stale data issues',
                submitBtn: 'Refresh Cache'
              },
              resetCrewList: {
                title: 'Reset Crew List',
                description: 'Reset Crew List Headers?',
                submitBtn: 'Reset'
              },
              outboxDelay: {
                title: 'Outbox delay',
                description: 'Set duration of delay on outbox before the message is distributed',
                options: {
                  thirtySeconds: '30 seconds',
                  oneMinute: '1 minute',
                  twoMinutes: '2 minutes'
                }
              },
              archiveFiles: {
                title: 'Archive Files',
                description: 'Set duration (days) Files are active for to keep production light:'
              },
              twoFactorAuth: {
                title: 'Two-Factor Authentication (2FA)',
                description: 'Secure your production beyond username and password with 2FA',
                modal: {
                  heading: 'Two-Factor Authentication (SMS)',
                  content: {
                    enable: 'Enabling 2FA using SMS requires all users to receive an SMS code to complete the login process. You will be asked to log in again once it\'s activated.',
                    disable: 'After disabling 2FA using SMS, only Username and Password will be needed for login.'
                  }
                },
                error: 'Error saving two-factor auth setting. Contact support@croogloo.com for assistance'
              }
            },
            userSettings: {
              savingSuccess: 'User Settings Successfully Saved',
              savingError: 'Error saving user settings',
              language: {
                title: 'Language',
                description: 'Select language here',
                options: {
                  french: 'Français',
                  english: 'English'
                }
              },
              emailAttachments: {
                title: 'Email Attachments',
                description: 'If attaching file(s) to email then:',
                options: {
                  always: 'Always provide information',
                  never: 'Never provide information'
                }
              },
              photoNotifications: {
                title: 'Photo Notifications',
                description: 'If there is activity related to photos you are showing then:',
                options: {
                  enabled: 'Get email notifications',
                  disabled: "Don't get notified"
                }
              },
              inactivityTimeout: {
                title: 'Inactivity Logout',
                description: 'Automatically log-out account after 20 minutes of inactivity.',
                options: {
                  enabled: 'Enabled',
                  disabled: 'Disabled'
                }
              },
              concurrentSessions: {
                title: 'Concurrent Sessions',
                description: 'Keep sessions active on different devices',
                options: {
                  enabled: 'Enabled',
                  disabled: 'Disabled'
                }
              },
              ipVerification: {
                title: 'IP Address Verification',
                description: 'IP address verification as part of the authentication process',
                options: {
                  enabled: 'Enabled',
                  disabled: 'Disabled'
                }
              },
              signature: {
                heading: 'Email Signature',
                error: 'Error saving signature'
              }
            }
          },
          slideDrawer: {
            buttonText: 'Lists',
            searchPlaceholder: 'Search Lists',
            tabs: {
              listTab: 'Lists',
              departmentTab: 'Departments',
              contactsTab: 'Contacts'
            }
          },
          templates: {
            page:
            {
              confirm: 'Confirm',
              download: 'Download',
              downloadAll: 'Download all',
              requestMore: 'Request More',
              selectFormat: 'Please select a format',
              describeYourTemplate: 'Describe your template here...',
              requestNewTemplate: 'Request New Template',
              pleaseLetUsKnow: 'Please let us know the type of templates \n you would like to add',
              templateRequestMade: 'Template Request Made',
              errorSendingRequest: 'Error Sending Template Request',
              fillOutTheForm: 'Please fill out the following form:',
              form: 'Form',
              errorLoadingFormData: 'Error Loading Form Data',
              crewInfoSheet: 'Crew Info Sheet'
            },
            'crew-intake': 'Crew Intake',
            'accident-rpt': 'Accident Report',
            'call-sheet': 'Call Sheet',
            'carbon-calc': 'Carbon Calculator',
            'cheque-req': 'Cheque Request',
            'contact-imp': 'Contact Import',
            dpr: 'Daily Production Report',
            'equip-ordr': 'Equipment Order',
            'gas-receipts': 'Gas Receipts',
            'green-seal': 'PEACH Green Seal',
            'loss-dmg': 'Loss and Damage',
            'mastercard-recpt': 'Mastercard Receipts',
            mileage: 'Mileage Form',
            'petty-req': 'Petty Cash Advance Req.',
            'petty-env': 'Petty Cash Envelope',
            'prod-chcklist': 'PEACH Production Checklist',
            lumber: 'PEACH Production Lumber Material Worksheet',
            'travel-memo': 'Travel Memo',
            'visa-recpt': 'VISA Receipts',
            allergyReport: 'Allergy Report'
          },
          compose: {
            form: {
              labels: {
                replyTo: 'Reply To Email',
                selectTemplate: 'Select Template',
                firstName: 'First Name',
                lastName: 'Last Name',
                startMessage: 'Start your message here...',
                recipients: 'Reply To:',
                subject: 'Subject:',
                to: 'To:',
                sendAsLinkChk: 'Send PDF Files as Links',
                attachmentSize: 'Attachments size: {{size}}/20 Mb'
              },
              draft: {
                draftSaved: 'Draft Successfully Saved',
                auto: 'automatically saved draft at {{time}}'
              },
              message: {
                noRecipients: 'No Recipients Selected',
                noSubject: 'No Subject Line',
                noSubjectContent: 'This distribution contains no subject line. Would you like to proceed?',
                confirm: 'Are you sure?',
                clearConfirm: 'This will clear all text fields, attachments and recipients, except for the signature.',
                clearMessage: 'Clear Message',
                previewMessage: 'This message will be sent as a test only to you.',
                previewHeading: 'Send Test Message',
                scheduleSend: 'Do you want to schedule send',
                scheduleSuccess: 'Success! Message Scheduled',
                confirmContent: 'You are sending this message to members of the following groups: {{groups}}.',
                sendingFilesError: 'There\'s a problem sending {{name}}. Please try uploading the file again or contact support@croogloo.com for assistance.',
                success: 'Success',
                willBeSentShortly: 'Your message will be sent shortly',
                withUnknown: '{{count}} recipient’s email did not match any of your contacts.',
                textSMSPlaceholder: 'Start message here for distribution notification by text'
              },
              validation: {
                nothingToSave: 'Nothing To Save'
              },
              action: {
                templateOptions: 'Template Options',
                deleteTemplate: 'Delete',
                saveAsTemplate: 'Save As Template',
                saveTemplate: 'Save',
                saveAs: 'Save As',
                insertRecipient: 'Insert Recipient Name(s)',
                uploadFiles: 'Upload Files',
                searchFiles: 'Find Files',
                textSms: 'Text SMS',
                preview: 'Preview',
                send: 'Send',
                scheduleSend: 'Schedule Send',
                sendConfirm: 'Yes send it!',
                confirmScheduleSend: 'Yes Please!'
              }
            },
            saveTemplate: {
              includeOptions: {
                title: 'Template Options',
                message: 'What should the template include?',
                items: {
                  subject: 'Subject',
                  emailBody: 'Message Body',
                  sms: 'SMS',
                  recipients: 'Recipients'
                }
              },
              attachmentOptions: {
                title: 'Template Options',
                message: 'Automatically attach the latest uploaded files of selected categories, if any. (optional)',
                attach: 'Attach the',
                latest: 'Latest'
              },
              templateName: {
                title: 'New Template',
                label: 'Template Name'
              },
              message: {
                success: 'Template Saved',
                error: 'Server Error saving template',
                noName: 'Please provide a name for the template.',
                selection: 'Please select at least one template component.',
                empty: 'All selected fields are empty',
                attachmentNumber: 'Invalid configuration. Please make sure all attachments are between {{min}} and {{max}}.',
                attachmentCategoryOne: 'Invalid configuration. Please make sure a category is selected for all attachments.',
                attachmentCategoryMultiple: 'Invalid configuration. Please make sure a category is only selected once.'
              }
            },
            deleteTemplate: {
              success: 'Successfully delete template',
              title: 'Delete Title',
              content: 'Are you sure you want to delete {{name}} This action cannot be undone.'
            },
            searchFiles: {
              SelectDocuments: {
                title: 'Select Documents',
                message: 'Select up to {{maxNumberOfFiles}} documents',
                inputPlaceholder: 'Search Documents'
              }
            },
            testMessage: {
              response: {
                success: 'Your test message has been sent.',
                noPhone: 'The email was sent successfully to your own email address, but you won\'t be able to receive SMS messages until a mobile phone number is added to your contact information.',
                fail: 'There was a problem sending your message. Please contact support if the problem persists.'
              }
            },
            templateWarning: {
              heading: 'Warning',
              content: 'The following text field(s) will be overwritten if you proceed:',
              labels: {
                subject: 'Subject',
                emailBody: 'Email Body',
                smsMessage: 'SMS',
                signature: 'Signature'
              }
            },
            invalidRecipients: {
              heading: 'Some recipients won\'t get the Email'
            },
            noAttachment: {
              heading: 'No Attachment',
              content: 'This distribution contains no attachment. Would you like to proceed?'
            },
            attachmentError: {
              heading: 'Attachments Too Large',
              content: 'The total size of all attachments must be under {{size}} Mb.'
            },
            removeVariables: {
              heading: 'Warning',
              contentOne: 'Variables will be removed for the {{count}} recipient who could not be found in your contacts.',
              contentOther: 'Variables will be removed for the {{count}} recipients who could not be found in your contacts.'
            },
            includeAgents: {
              heading: 'Include Agents',
              contentOne: 'Cast member(s) were selected and {{count}} of their agents was not ({{titles}}). Would you like to include this missing agent as a recipient?',
              contentOther: 'Cast member(s) were selected and {{count}} of their agents were not ({{titles}}). Would you like to include these missing agents as recipients?'
            },
            inactiveContacts: {
              heading: 'Include Inactive Contacts?',
              content: 'The following contacts are inactive on {{sendDate}} : {{titles}}. Would you like to include them as recipients?'
            },
            missingFiles: {
              heading: 'MissingFiles',
              content: 'The following files may have been deleted: {{name}}. Please upload them again to continue'
            },
            linkOptions: {
              heading: 'Link Options',
              holders: {
                content: 'Link holders have permission to...',
                options: {
                  view: 'View',
                  download: 'View & Download',
                  print: 'View, Download & Print'
                }
              },
              expire: {
                content: 'Links will expire...',
                days: 'days and',
                hours: 'hours',
                options: {
                  never: 'Never',
                  h24: 'After 24h',
                  custom: 'Custom'
                }
              },
              error: {
                days: 'Number of days should be a whole number between 0 and 1000',
                hours: 'Number of hours should be a whole number between 0 and 1000',
                total: 'Please enter a value greater than 0 for expiry time'
              }
            },
            invalidEmail: 'Invalid email address: {{email}}',
            pendingContacts: {
              heading: 'Pending Contacts',
              error: 'Please select an email to load'
            },
            scheduleSend: {
              error: {
                reject: 'The date and time scheduled must be a valid future date.'
              }
            },
            attachment: {
              exists: 'A file by the name "{{fileName}}" was already attached.'
            },
            editor: {
              toolbar: {
                font: {
                  arial: 'Arial',
                  comicSans: 'Comic Sans',
                  courierNew: 'Courier New',
                  georgia: 'Georgia',
                  helvetica: 'Helvetica',
                  lucida: 'Lucida'
                },
                fontSize: {
                  small: 'Small',
                  medium: 'Normal',
                  large: 'Large',
                  heading: 'Heading',
                  normal: 'Normal',
                  heading1: 'Heading 1',
                  heading2: 'Heading 2',
                  heading3: 'Heading 3'
                }
              }
            }
          },
          scripts: {
            slideDrawer: {
              tabHeader: 'Scripts',
              button: 'Scripts',
              moreOptions: {
                download: 'Download',
                callsheet: 'Attach Call Sheet',
                parse: 'Parse Script',
                watermark: 'Apply Watermark',
                delete: 'Delete'
              }
            },
            viewer: {
              parseFile: {
                heading: 'Script Information',
                content: '',
                version: 'Script Version',
                color: 'Select a Color',
                episode: 'Episode',
                error: {
                  noFile: 'Please provide a file',
                  server: 'Error parsing file',
                  color: 'Parsed scripts need a color',
                  pdf: 'Script parsing is currently offered for PDF files only'
                },
                success: 'Script Successfully Parsed'
              },
              deleteFile: {
                heading: 'Delete "{{name}}"',
                content: 'You will not be able to recover this file',
                success: '"{{name}}" Deleted'
              },
              watermark: {
                success: 'Watermark Applied',
                error: {
                  noFile: 'Please provide a file',
                  server: 'Error applying watermark',
                  selection: 'Please make a selection',
                  other: 'Please fill in the "Other" text field'
                }
              }
            }
          },
          sides: {
            slideDrawer: {
              button: 'Sides',
              tabHeader: 'Sides'
            },
            attachCallsheet: {
              heading: 'Attach Call Sheet',
              scriptVersion: 'Script Version',
              scriptVersionLabel: 'Select a Call Sheet to attach to Sides',
              pageNumberLabel: 'Which page(s) would you like to attach?',
              pageNumberLabelDescription: 'Leave the box empty to select all pages',
              placeholder: 'Ex: 1,3,5-8',
              errors: {
                noCallSheet: 'Error Attach Call Sheet',
                server: 'Error Attaching Call Sheet. Please contact support.'
              },
              success: 'Call Sheet successfully attached to {{name}}'
            }
          },
          upload: {
            uploadLabel: 'Click to Upload or Drag & Drop',
            fileInformation: {
              title: 'File Information',
              category: {
                label: 'Category',
                placeholder: 'Select Category'
              },
              episode: {
                label: 'Episode',
                placeholder: 'Episode Number (if applicable)'
              },
              shared: {
                label: 'Share with'
              }
            },
            selectCategory: {
              title: 'Select Category'
            },
            scriptInformation: {
              content: 'Use for Sides?'
            },
            fileType: {
              title: 'Select File Type',
              error: 'Please select a file type to continue'
            },
            fileAlreadyExists: {
              title: 'File Already Exists',
              label: 'Name Your File',
              compose: {
                content: 'A file by the name {{fileName}} was found in the {{folderName}} folder. Would you like to attach it instead of uploading this new file?',
                no: 'No'
              },
              other: {
                content: 'A file by the name {{fileName}} was found in the {{folderName}} folder. We recommend using a different name to avoid duplicates'
              },
              error: {
                attachExisting: 'There was a problem attaching the existing file.'
              }
            },
            fileIsTooLarge: {
              title: 'File size is too large',
              content: 'Please select file of size less then {size} megabytes.',
              ok: 'OK'
            },
            fileWatermark: {
              title: 'Apply Watermark',
              message: '',
              line1: {
                title: 'Line 1',
                label: '',
                placeholder: 'Type Your label'
              },
              line2: {
                title: 'Line 2',
                label: '',
                placeholder: 'Type Your label'
              },
              options: {
                recipientsName: 'Recipients Name',
                title: 'Title',
                department: 'Department',
                date: 'Date',
                ipAddress: 'IP Address',
                blank: 'Blank',
                other: 'Other'
              },
              error: {
                other: 'The "Other" text field cannot be left empty. Press Skip to upload without a watermark.'
              }
            },
            fileDestination: {
              title: 'Upload Destination',
              error: {
                selection: 'Please make a selection'
              }
            },
            error: {
              selectFile: 'Please select a file',
              selectMax: 'Please select a maximum of {{max}} files',
              server: 'Something went wrong uploading your file. Please contact support for more information.'
            },
            selectFiles: {
              success: 'Files successfully added'
            },
            applyToAll: {
              heading: 'Apply to all',
              content: 'Would you like to apply these settings to other uploaded files?'
            },
            fileImport: {
              success: 'Successfully imported folder'
            }
          },
          action: {
            clear: 'Clear',
            save: 'Save',
            ok: 'Ok',
            proceed: 'Proceed',
            confirm: 'Confirm',
            skip: 'Skip',
            cancel: 'Cancel',
            close: 'Close',
            next: 'Next',
            upload: 'Upload',
            abort: 'Abort',
            delete: 'Delete',
            logout: 'Logout',
            select: 'Select',
            download: 'Download',
            keep: 'No, Keep It!',
            copyRecipients: 'Copy Recipients',
            viewReport: 'View Report',
            viewMessage: 'View Message',
            forward: 'Forward',
            addContacts: 'Add Contacts',
            importContacts: 'Import Contacts',
            submit: 'Submit',
            merge: 'Merge',
            export: 'Export',
            addDepartment: 'Add Department',
            addAgent: 'Add Agent',
            addMember: 'Add Cast',
            okay: 'okay',
            share: 'Share',
            create: 'Create',
            remove: 'Remove',
            yes: 'Yes',
            gotIt: 'Got It!',
            no: 'No',
            include: 'Yes, Include',
            duplicate: 'Duplicate',
            emptyDrafts: 'Empty Drafts',
            load: 'Load',
            addNow: 'Add Now',
            copy: 'Copy!',
            empty: 'Empty',
            pay: 'pay'
          },
          distributionHistory: {
            recipients: 'Recipients',
            pageTitle: {
              Drafts: 'Drafts',
              Outbox: 'Outbox',
              Sent: 'Sent'
            },
            placeholder: {
              searchEmails: 'Search Sent'
            },
            drafts: {
              restore: 'Restore Draft'
            },
            outbox: {
              header: {
                subject: 'Subject',
                scheduled: 'Scheduled'
              },
              abort: {
                heading: 'Abort Message',
                content: 'Would you like to abort this message? It will be saved as a draft.',
                confirm: 'Confirm',
                toast: 'Distribution canceled, message saved as a draft.',
                load: 'click to load',
                error: 'Error aborting message. Contact support if the problem persists'
              },
              sending: 'Sending',
              failed: 'Failed'
            },
            sent: {
              openReport: 'Open Report',
              closeReport: 'Close Report'
            },
            copyRecipients: {
              saveList: 'Save as a List',
              heading: 'Copy Recipients',
              content: 'What would you like to do with these recipients?',
              confirm: 'Send Email'
            },
            addDistribution: {
              heading: 'Add Distribution List',
              label: 'Enter List Name'
            },
            deleteDraft: {
              heading: 'Are you sure?',
              content: 'You will not be able to recover this draft if you delete it.'
            },
            emptyDrafts: {
              heading: 'Empty Drafts',
              content: 'Are you sure you want to empty drafts? You will not be able to recover them once deleted.',
              empty: 'There are no drafts to delete'
            }
          },
          securityGroups: {
            headers: {
              name: 'NAME',
              department: 'DEPARTMENT',
              position: 'POSITION'
            }
          },
          distributionList: {
            selectAList: 'Select A List',
            addList: {
              button: 'Add List',
              heading: 'Add Distribution List',
              content: 'Name',
              error: {
                name: 'The name field cannot be left empty.',
                server: 'Server Error. Couldn\'t Add List.'
              },
              success: 'Distribution List {{name}} added'
            },
            listOptions: {
              label: 'List Options',
              editListName: 'Edit List Name',
              duplicate: 'Duplicate',
              mergeLists: 'Merge Lists',
              export: 'Export',
              compareLists: 'Compare Lists',
              delete: 'Delete',
              empty: 'List Options'
            },
            removeSelected: 'Remove Selected',
            addSelected: 'Add Selected',
            tabs: {
              membersTab: 'Members',
              nonMembersTab: 'Non-Members'
            },
            modifyContact: {
              heading: 'Modify Contact',
              firstName: 'First Name*',
              lastName: 'Last Name*',
              email: 'Email*',
              secondEmail: 'Second Email',
              otherEmails: 'Other Emails',
              phone: 'Phone',
              error: {
                fetch: 'Failed to fetch contact',
                empty: 'The above highlighted fields cannot be empty',
                valid: 'The above highlighted fields are invalid',
                server: 'Failed to modify contact'
              },
              success: 'Contact Successfully Modified'
            },
            editListName: {
              heading: 'Edit List Name',
              label: 'New Name',
              error: {
                server: 'Server Error. Couldn\'t Edit List Name.'
              },
              success: 'Distribution List {{name}} Renamed'
            },
            duplicate: {
              heading: 'Duplicate Distribution List',
              label: 'New Name',
              error: {
                server: 'Server Error. Couldn\'t Duplicate Name.'
              },
              success: 'Distribution List {{name}} Duplicated'
            },
            mergeList: {
              heading: 'Merge Lists',
              label: 'New List Name',
              mergeIntoCurrent: 'Merge Into {{name}}',
              mergeIntoOther: 'Merge Into New List',
              selectListToMergeWithDailyTesting: 'Select list(s) to merge with {{listName}}',
              error: {
                count: 'Cannot merge empty list.',
                all: 'Cannot merge all contacts list',
                empty: 'Please select a list to merge.',
                server: 'Server Error. Couldn\'t Merge Lists.'
              },
              success: 'Distribution Lists {{name}}, {{other}} Merged'
            },
            exportList: {
              heading: 'Export Distribution Lists',
              selectLabel: 'Select Lists',
              selectAll: 'Select All',
              selectListToExport: 'Select distribution list(s) to export',
              error: {
                empty: 'No list selected',
                server: 'Server Error. Couldn\'t Export Lists.'
              },
              download: 'Generating File...'
            },
            exportOptions: {
              heading: 'Export Options',
              content: 'Please select an option.',
              error: {
                empty: 'No Option Selected'
              },
              options: {
                excel: 'Excel sheet (.xls)',
                vcard: 'vCard contacts (.vcf)',
                csv: 'Google Contacts (.csv)'
              }
            },
            deleteList: {
              heading: 'Delete Distribution List',
              content: '{{name}} will be deleted',
              success: '{{name}} Deleted',
              error: {
                server: 'Server Error. Couldn\'t Delete List.'
              }
            },
            compareLists: {
              heading: 'Compare List',
              compare: 'Compare',
              toAnotherList: 'to another list',
              label: 'Select a list to Compare',
              table: {
                headers: {
                  only: 'Only in {{name}}',
                  both: 'In Both',
                  compare: 'Only in {{name}}'
                }
              }
            },
            update: {
              error: 'Failed to update distribution list'
            },
            table: {
              headers: {
                name: 'Name',
                department: 'Department',
                job: 'Position',
                list: 'Distribution Lists'
              }
            }
          },
          distributionReport: {
            title: 'Distribution Report',
            datasetLabel: ' # of recipients',
            successMessages: 'Success:',
            failMessages: 'Fail:',
            status: {
              Sent: 'Sent',
              processed: 'Sent',
              delivered: 'Received',
              opened: 'Opened',
              bounced: 'Bounced',
              deferred: 'Deferred',
              dropped: 'Dropped'
            },
            tableHeader: {
              name: 'Name',
              email: 'Email',
              status: 'Status'
            }
          },
          contacts: {
            pageTitle: 'Contacts',
            table: {
              moreOptions: {
                invite: 'Send Invite',
                edit: 'View/Edit',
                email: 'Email',
                text: 'Text (SMS)',
                category: 'Change Category',
                department: 'Change Department',
                addDistribution: 'Add Distribution List',
                editDistribution: 'Edit Distribution Lists',
                delete: 'Delete',
                importContacts: 'Import Contacts',
                exportContacts: 'Export Contacts'
              },
              headers: {
                name: 'Name',
                castNumber: '#',
                lists: 'Lists',
                email: 'Email',
                phone: 'Phone'
              },
              phoneLabels: {
                mobile: 'm:',
                office: 'o:',
                home: 'h:'
              },
              saveSuccess: 'Successfully updated crew relative order',
              saveError: 'Error updating crew relative order',
              crew: {
                saveChanges: 'SAVE CHANGES',
                orderMembers: 'Order Members',
                addCrewMember: 'Add Crew Member'
              }
            },
            general: {
              buttons: {
                manageDistribution: 'Manage Lists',
                setEmailDoods: 'Set DooDs',
                inviteContact: 'Invite Contact',
                addContact: 'Add Contact',
                generateCrewList: 'Crew List',
                invite: 'Send Invite',
                text: 'Text',
                email: 'Email',
                addDistribution: 'Add To List',
                editContact: 'View/Edit',
                linkCast: 'Link Cast',
                linkAgent: 'Link Agent',
                addCast: 'Add Cast',
                addAgent: 'Add Agent',
                addVendor: 'Add Vendor',
                addStudio: 'Add Studio',
                addUnion: 'Add Union',
                options: 'Options'
              }
            },
            generateCrewList: {
              header: 'Generate Crew List',
              description: 'Customize your crew list details, then download or share through distribution. Tip: Contacts without a department assigned will not appear in your crew list.',
              addProductionLogo: 'Add Production Logo',
              addHeaderInfo: 'Add Header Info',
              setDepartmentOrder: 'Set Department Order',
              clearHeadersDisclaimer: 'To reset crew list headers, go to your production settings',
              leftHeader: 'Left Header',
              rightHeader: 'Right Header',
              uploadLogo: 'Upload Logo',
              pngOrJpg: '(png or jpeg accepted)',
              buttons: {
                getStarted: 'Get Started',
                confirmLogo: 'Confirm Logo',
                confirmHeader: 'Confirm Header',
                confirmDepartmentOrder: 'Confirm Department Order'
              },
              crewListVersion: {
                header: 'Crew List Version',
                description: 'Select version color for storage and output option. Tip: Crew List is automatically stored in your Documents > Crew List folder',
                downloadExcel: 'Download Excel',
                downloadPDF: 'Download PDF',
                sendAsDistribution: 'Send As Distribution',
                selectColor: 'Select a Color',
                processing: 'Processing...',
                errorGeneratingPDF: 'Error generation crew list PDF',
                errorReceivingPDFLink: 'Error receiving crew list PDF link',
                errorFetchingDocument: 'Error fetching document'

              }
            },
            addContact: {
              saveSuccess: 'Contact successfully saved',
              saveAndInviteSuccess: 'Contact successfully saved. Invite sent.',
              saveError: 'Error saving contact',
              account: 'ACCOUNT',
              exitWithoutSaving: 'Quit without saving?',
              exitProceed: 'Are you sure you want to proceed?',
              emailAlreadyTaken: 'This email address is already taken',
              basicInfo: {
                heading: 'Basic Info',
                category: {
                  label: 'Category',
                  crew: 'Crew',
                  cast: 'Cast',
                  agent: 'Agent',
                  studio: 'Studio',
                  union: 'Union',
                  vendor: 'Vendor'
                },
                firstName: {
                  label: 'First Name',
                  placeholder: 'First'
                },
                lastName: {
                  label: 'Last Name',
                  placeholder: 'Last'
                },
                email: {
                  label: 'Email',
                  placeholder: 'email@emailclient.com'
                },
                addEmail: {
                  label: 'Add Email'
                },
                phone: {
                  label: 'Phone',
                  placeholder: '1 (234) 567-8900'
                },
                department: {
                  label: 'Department'
                },
                position: {
                  label: '',
                  placeholder: 'Position',
                  studioPlaceholder: 'STUDIO NAME',
                  unionPlaceholder: 'UNION NAME',
                  vendorPlaceholder: 'VENDOR NAME'
                },
                castNumber: {
                  label: 'Cast #',
                  placeholder: '123'
                },
                characterName: {
                  label: 'Character Name',
                  placeholder: ''
                },
                inviteToCroogloo: {
                  label: 'Invite to Croogloo'
                },
                agent: {
                  label: 'Agent Name',
                  placeholder: 'Select associate'
                },
                cast: {
                  label: 'Cast Name'
                },
                agency: {
                  label: 'Agency',
                  placeholder: 'Agency Name'
                },
                securityGroup: {
                  label: 'Security Group',
                  placeholder: 'Security Group'
                }
              },
              moreInfo: {
                heading: 'More Info',
                otherEmails: {
                  label: 'Other Emails',
                  placeholder: ''
                },
                additionalEmail: {
                  label: 'Additional Email',
                  placeholder: ''
                },
                officePhone: {
                  label: 'Office Phone',
                  placeholder: ''
                },
                homePhone: {
                  label: 'Home Phone',
                  placeholder: ''
                },
                address: {
                  label: 'Address',
                  placeholder: ''
                },
                city: {
                  label: 'City',
                  placeholder: ''
                },
                state: {
                  label: 'State/Province',
                  placeholder: ''
                },
                postalCode: {
                  label: 'Zip Code',
                  placeholder: ''
                },
                creditName: {
                  label: 'Name shall appear on credit  as follows',
                  placeholder: ''
                },
                contactName: {
                  label: 'Emergency Contact Name',
                  placeholder: ''
                },
                contactNumber: {
                  label: 'Emergency Contact Number',
                  placeholder: ''
                },
                allergies: {
                  label: 'Allergies',
                  placeholder: ''
                },
                gift: {
                  label: 'Wrap Gift Size',
                  placeholder: 'Size'
                },
                doods: {
                  heading: 'DooDs'
                }
              },
              startEndDates: {
                heading: 'Start & End Dates',
                startDate: {
                  label: 'Start Distribution Date',
                  successfullyAdded: 'Email Start Date Successfully Added',
                  placeholder: 'Add email start date'
                },
                endDate: {
                  label: 'Finish Distribution Date',
                  successfullyAdded: 'Email End Date Successfully Added',
                  placeholder: 'Add email end date'
                },
                holdDate: {
                  label: 'Hold Date(s) for email distribution'
                }
              },
              inactiveDays: {
                heading: 'Inactive Days',
                successfullyAdded: 'Email Inactive Dates Successfully Added',
                placeholder: 'Hold Distribution Date(s)',
                startDate: {
                  label: 'Start Date for email distribution'
                },
                endDate: {
                  label: 'End Date for email distribution'
                },
                holdDate: {
                  label: 'Hold'
                },
                addInactiveDays: {
                  label: 'Add Hold Date(s)'
                }
              },
              addToDistributionLists: 'Distribution Lists',
              form: {
                error: {
                  email: '{{email}} is not a valid email address',
                  name: '{{name}} is not a valid name',
                  castNumber: 'Cast numbers must start with a digit and contain only digits and letters, without spaces.',
                  emailTaken: 'This email address is already taken'
                }
              }
            },
            importContacts: {
              heading: 'Import Contacts',
              content: 'Select your desired format to import contacts',
              label: 'Gmail'
            },
            exportContacts: {
              heading: 'Select Contacts',
              content: 'Please select an option for file output'
            },
            emailDooDs: {
              heading: 'Select Contacts',
              content: 'Select contact(s) to set email start date, hold days, and end date for your crew or contact(s).',
              castHeading: 'Select Cast',
              castConfirm: 'Copy!',
              castContent: 'Select cast to set email start date, end date, and pause day(s) for your cast.',
              agentHeading: 'Select Agent',
              agentContent: 'Select agent to set email start date, end date, and pause day(s).'
            },
            inviteContact: {
              heading: 'Select security group for account access',
              content: 'An email will be sent to {{name}} shortly',
              label: '',
              error: {
                empty: 'Please select a Security Group'
              },
              success: 'Invite sent to email'
            },
            changeCategory: {
              heading: 'Change Category',
              content: 'This Change will affect this {{name}}',
              label: 'Admin',
              error: {
                empty: 'Please select a category',
                server: 'Problem changing category. Please try again'
              },
              success: 'Category successfully changed'
            },
            changeDepartment: {
              heading: 'Change Department',
              content: 'This Change will affect this {{name}}',
              label: 'Select Department',
              error: {
                empty: 'Please select a department',
                server: 'Problem changing department. Please try again'
              },
              success: 'Department successfully Added'
            },
            addDepartment: {
              heading: 'New Department',
              label: 'Department',
              successfullyAdded: 'Department Successfully Added',
              error: {
                empty: 'Please input your new department\'s name.',
                server: 'Error creating department',
                duplicate: 'This department already exists'
              }
            },
            newContact: {
              agent: {
                heading: 'New Agent',
                content: ''
              },
              cast: {
                heading: 'New Cast',
                content: ''
              },
              firstName: {
                label: 'First Name*',
                placeholder: '',
                error: {
                  empty: 'First Name cannot be empty',
                  invalid: 'First Name is not valid'
                }
              },
              lastName: {
                label: 'Last Name*',
                placeholder: '',
                error: {
                  empty: 'Last Name cannot be empty',
                  invalid: 'Last Name is not valid'
                }
              },
              position: {
                label: 'Position*',
                placeholder: '',
                error: {
                  empty: 'Position cannot be empty'
                }
              },
              email: {
                label: 'Email*',
                placeholder: '',
                error: {
                  empty: 'Email cannot be empty',
                  invalid: 'Email is not valid'
                }
              },
              phone: {
                label: 'Phone',
                placeholder: ''
              }
            },
            AddToDistribution: {
              heading: 'Add to Distribution List',
              content: '{{name}} will be added',
              label: 'Select Distribution list',
              contentMore: '{{count}} contacts will be added',
              error: {
                noList: 'Please select a list'
              },
              success: 'Successfully added to Distribution List'
            },
            deleteContact: {
              heading: 'Deleting {{category}} Member',
              headingMore: 'Deleting Members',
              content: 'Are you sure you want to delete',
              contentMore: 'Are you sure you want to delete these {{count}} contacts?',
              success: '{{name}} was successfully deleted',
              successMore: '{{count}} contacts were successfully deleted',
              error: 'Problem deleting contact. Please contact support'
            },
            linkAgent: {
              heading: 'Select Cast',
              content: 'Select Cast to link to Agent to simplify email distribution to your Cast. Organize contacts into categories to connect all the dots.'
            },
            linkCast: {
              heading: 'Select Agent',
              content: 'Select agent to set email start date, end date, and pause day(s).'
            },
            invitationFailed: {
              heading: 'Invitation Failed',
              content: 'The following contact could not be invited: {{name}} (user already invited)'
            },
            textSms: {
              listPlaceholder: 'Select Distribution List(S)',
              contactPlaceholder: 'Select Recipients or Enter New Phone Numbers',
              bodyPlaceholder: 'Enter Message Here For Phone Notification',
              error: {
                emptyMessage: 'Cannot send an empty message',
                noRecipient: 'No recipient selected',
                server: 'Something went wrong sending your text. Please contact support for more information.'
              },
              success: 'Text message successfully sent'
            }
          },
          common: {
            fields: {
              time: 'Time',
              date: 'Date'
            },
            labels: {
              dateTime: 'Pick Date & Time',
              watermarked: 'Watermarked',
              searchFiles: 'Search Files',
              searchEmails: 'Enter Email Address(es)',
              searchContacts: 'Search ',
              error: 'Error',
              back: 'Back',
              watermarkedShort: 'WM',
              archived: 'Archived',
              of: 'of',
              noOptions: 'No Options'
            },
            tooltips: {
              removeAttachment: 'Remove Attachment'
            },
            sorting: {
              options: {
                ascending: 'Ascending Order (A-Z)',
                descending: 'Descending Order (Z-A)',
                newest: 'Newest First',
                oldest: 'Oldest First'
              }
            },
            colors: {
              white: 'White',
              blue: 'Blue',
              pink: 'Pink',
              yellow: 'Yellow',
              green: 'Green',
              goldenrod: 'Goldenrod',
              buff: 'Buff',
              salmon: 'Salmon',
              cherry: 'Cherry',
              tan: 'Tan',
              lavender: 'Lavender',
              select: 'Select a Color'
            },
            versions: {
              script: 'Script Version',
              draft: 'Draft',
              single: 'Single',
              double: 'Double',
              triple: 'Triple',
              quadruple: 'Quadruple',
              quintuple: 'Quintuple'
            },
            time: {
              seconds: 'secs'
            },
            states: {
              usStates: 'U.S. States/Territories',
              canadianProvinces: 'Canadian Provinces'
            },
            fileTypes: {
              photo: 'Photo',
              sides: 'Sides',
              revision: 'Revision',
              scriptTiming: 'Script Timing',
              script: 'Script',
              prepschedule: 'Prep Schedule',
              shootSched: 'Shooting Schedule',
              prodSched: 'Production Schedule',
              maps: 'Maps',
              other: 'Other',
              clearance: 'Clearance',
              breakdown: 'Breakdown',
              dpr: 'DPR',
              dood: 'DooD',
              oneliner: 'Oneliner',
              crewList: 'Crew List',
              chrono: 'Chrono',
              castList: 'Cast List',
              callSheets: 'Call Sheet',
              techsurvey: 'Tech Survey',
              onelineSched: 'Oneline Schedule'
            },
            thirdParty: {
              error: {
                denied: '{{thirdParty}} unauthorized error. If this problem persist, try unlinking your account in the 3 dots menu and linking it back after.',
                load: 'Error loading {{thirdParty}} Account. If this problem persist, try unlinking your account in the 3 dots menu and linking it back after.',
                merge: 'Unable to merge {{thirdParty}} files, please import them first.',
                connection: 'Unable to contact the {{thirdParty}} services at this time, please try again later.'
              }
            },
            handleInactivity: {
              heading: 'Inactive Page',
              content: 'You have been inactive for over {{MAX_IDLE_MINS}} minutes. We will log you out in less than 30 seconds to protect your data.',
              stay: 'Stay Connected'
            }
          },
          watermark: {
            submitBtn: 'Watermark',
            drawer: {
              buttonText: 'Lists',
              emailPlaceholder: 'Enter additional contact(s)',
              addNew: 'Press enter to add:'
            },
            searchBar: {
              placeholder: 'Search Lists'
            },
            firstLine: {
              name: 'Name',
              label: 'Label'
            },
            secondLine: {
              blank: 'Blank',
              label: 'Label',
              title: 'Title',
              department: 'Department',
              date: 'Date',
              ipAddress: 'IP Address',
              other: 'Other'
            },
            labelInput: {
              placeholder: 'Enter Text'
            },
            submitPopup: {
              title: 'Process Initiated',
              content: 'Your file is being watermarked. Click the notification bell on the top right corner of your screen to monitor progress.',
              error: {
                max: 'Please select a maximum of {{max}} files',
                empty: 'Please select a document to watermark',
                selection: 'Please make a selection for both Line 1 and 2',
                specifyFirst: 'Please specify the first line\'s content',
                specifySecond: 'Please specify the second line\'s content',
                noContact: 'No contact selected.',
                failure: 'Something went wrong. Please contact support.'
              },
              success: 'File Generating...'
            },
            sections: {
              lineOne: 'Line 1',
              lineTwo: 'Line 2',
              outputOptions: {
                title: 'Output Options',
                combined: 'Combined PDF',
                individual: 'Individial PDF - ZIP Folder'
              }
            },
            buttons: {
              opacity: 'Opacity',
              clear: 'Clear'
            }
          },
          generateSides: {
            tabs: {
              latestVersions: 'Latest Versions',
              otherScripts: 'Other Scripts',
              scene: 'Scene',
              character: 'Character',
              location: 'Location'
            },
            drawer: {
              buttonText: 'Scripts'
            },
            searchBar: {
              placeholder: 'Search Scripts'
            },
            quickSceneSelect: {
              placeholder: 'Quick Scene Selection'
            },
            scriptOrder: 'Script Order',
            formatOptions: {
              heading: 'Format Options',
              noScene: 'No scene selected',
              fileOptions: {
                label: 'File Name',
                upItems: {
                  default: '1-UP',
                  other: '2-UP'
                },
                formatItems: {
                  default: '17 X 11 in (default)',
                  other: '11 X 8.5'
                }
              },
              unselectedScenes: {
                UnselectedContent: 'Unselected Scenes',
                selectedContent: 'Selected Scenes',
                sceneItems: {
                  crossMask: 'Cross out and mask',
                  hide: 'Hide',
                  cross: 'Cross'
                },
                sceneNumber: {
                  label: 'Circle scene number'
                },
                arrow: {
                  label: 'Add continuity arrow'
                },
                colorPicker: {
                  label: 'Select Color'
                }
              },
              success: 'Sides are generating and available from Sides page upon notification.',
              successHeader: 'Success',
              errors: {
                fileName: 'Please provide a file name for your sides.',
                format: 'Please select your desired output format.',
                characters: 'Invalid characters in file name',
                incompatible: 'Incompatible scripts. Parse them again to enable this feature.',
                server: 'Server Error. Please try again later'
              }
            }
          },
          managePermissions: {
            tabs: {
              groupsTab: 'Groups'
            },
            appPermissions: 'App Permissions',
            contacts: 'Contacts',
            contactsInfo: 'Agent, All, Cast, Crew, Studio, Union, Upload vCards, Vendor',
            viewer: 'Viewer',
            dailyTimeReports: 'Daily Time Reports',
            dashboard: 'Dashboard',
            editor: 'Editor',
            distribution: 'Distribution',
            distributionInfo: 'Compose, Distribution Lists, Drafts, Outbox, Sent',
            noAccess: 'No Access',
            documents: 'Documents',
            documentsInfo: 'Files, Photos, Upload, Watermark',
            scripts: 'Scripts',
            scriptsInfo: 'Scripts, Sides, Generate Sides, Upload',
            security: 'Security',
            securityInfo: 'Manage Permissions, Security Groups, Production Settings, Monitor Tasks',
            templates: 'Templates',
            templatesInfo: 'Templates, Upload',
            permissionOptions: {
              no: 'NO',
              ro: 'RO',
              rw: 'RW'
            },
            savingPermissions: 'Saving Permissions',
            allSaved: 'All Saved'
          },
          securityGroup: {
            newGroup: 'New Group',
            deleteGroup: 'Delete Group',
            addSelected: 'Add Selected',
            removeSelected: 'Remove Selected',
            securityGroupName: 'Security group name',
            delete: {
              heading: 'Delete "{{group}}"',
              content: 'You will not be able to restore this group',
              errorAdmin: 'The ADMIN list cannot be deleted.',
              serverError: 'Server Error. The list could not be deleted.',
              success: '{{name}} Deleted'
            },
            addGroup: {
              heading: 'Add Security Group',
              errorEmpty: 'The name field cannot be left empty',
              errorDigit: 'The list\'s name cannot start with a digit',
              errorAdmin: 'That name is reserved for the default\'s administrator list.',
              serverError: 'Server Error. The list could not be created.',
              success: '{{name}} successfully added'
            },
            confirmLoss: {
              heading: 'Warning',
              content: {
                admin: 'Removing yourself from the ADMIN group could make you lose access to multiple pages and functionalities. Are you sure you want to proceed?',
                list: 'Removing yourself from a security group will lock you out of this production.'
              },
              serverError: 'Server Error. Couldn\'t change security groups',
              error: 'Error. Couldn\'t change security groups',
              add: {
                success: '{{contact}} Added to {{name}}',
                successMore: 'Contacts Added to {{name}}'
              },
              remove: {
                success: '{{contact}} Removed from {{name}}',
                successMore: 'Contacts Removed from {{name}}'
              }
            }
          },
          documents: {
            pageTitle: 'Documents',
            buttons: {
              publish: 'Publish To Studio',
              options: 'Options'
            },
            table: {
              recents: 'Recents',
              headers: {
                icon: '',
                name: 'Name',
                modified: 'Modified',
                sharedWith: 'Shared With',
                more: ''
              }
            },
            more: {
              move: 'Move To',
              rename: 'Rename',
              downloadZip: 'Download as Zip',
              download: 'Download',
              accessLevel: 'Access Level',
              delete: 'Delete',
              folder: 'Folder',
              shared: 'Shared Folder',
              forms: 'Forms',
              reports: 'Reports',
              memos: 'Memos',
              spreadsheets: 'Spreadsheets',
              templates: 'Templates',
              box: 'Box',
              dropbox: 'Dropbox',
              drive: 'Google Drive',
              sharepoint: 'Sharepoint',
              copyLink: 'Copy Link',
              sendAndTrack: 'Send And Track',
              sendForSignature: 'Send For Signature',
              connectApps: 'Connect Apps',
              recover: 'Recover',
              sendByEmail: 'Send By Email',
              editCategory: 'Edit Category',
              parse: 'Parse Script',
              removeWatermark: 'Remove Watermark',
              applyWatermark: 'Apply Watermark',
              printWatermark: 'Watermark To Print',
              merge: 'Merge',
              archive: 'Archive',
              unlink: 'Unlink',
              link: 'Link',
              studioHub: 'Studio Hub',
              email: 'Email',
              setAccess: 'Set Access',
              importCroogloo: 'Import to Croogloo'
            },
            sharedWith: {
              everyone: 'Everyone',
              email: 'Shared By Email',
              notShared: 'Not Shared'
            },
            newFolder: {
              heading: 'New Folder',
              content: 'Folder Name',
              success: 'New Folder Created',
              error: {
                empty: 'Must set a folder name',
                server: 'Failed to add folder...'
              },
              publishToStudio: {
                success: 'New Publish to Studio Folder Created'
              }
            },
            sharedFolder: {
              heading: 'New Shared Folder',
              content: 'Folder Name'
            },
            moveDocument: {
              heading: 'Move Folder(s)/File(s)',
              headingFilesOnly: 'Move File(s)',
              headingFoldersOnly: 'Move Folder(s)',
              content: 'Select a folder to move document to',
              error: {
                server: 'Something went wrong. Please contact support if the problem persists.',
                empty: 'Please select a destination to move documents to'
              },
              success: 'Documents have been successfully moved'
            },
            removeWatermark: {
              heading: 'Remove Watermark',
              content: 'Remove watermark on {{name}}?',
              success: 'Watermark successfully removed',
              errors: {
                noFile: 'Please provide a file to remove watermark',
                server: 'Problem removing watermark. Contact support if the problem persists.'
              }
            },
            deleteDocument: {
              heading: 'Delete {{type}}',
              content: 'Are you sure you want to delete {{name}}',
              success: '{{name}} deleted',
              error: 'Problem deleting. Please contact support if the problem persists.'
            },
            eSignature: {
              heading: 'Feature Coming soon!',
              content: 'E-signatures are awesome. We are working on bringing it to our app soon!'
            },
            sharepoint: {
              heading: 'Feature Coming soon!',
              content: 'We are working on bringing this to our app soon!'
            },
            renameFile: {
              heading: 'Rename',
              success: '{{name}} successfully Renamed',
              errors: {
                name: 'Please provide a name',
                server: 'Error editing File Name'
              }
            },
            download: {
              started: 'Download Started',
              error: 'Something went wrong!',
              success: 'Downloading File',
              successMore: 'Downloading Zip File "{{name}}"'
            },
            archive: {
              successMulti: 'Files successfully archived',
              successSingle: 'File successfully archived',
              error: 'Problem archiving. Please contact support if the problem persists.'
            },
            recover: {
              successMulti: 'Files successfully recovered',
              successSingle: 'File successfully recovered',
              error: 'Problem recovering. Please contact support if the problem persists.'
            },
            editCategory: {
              heading: 'Update File Category',
              label: 'Select Category',
              error: {
                empty: 'No category selected',
                server: 'Something went wrong. Please contact support if the problem persists.'
              },
              success: 'File\'s category successfully changed to {{category}}'
            },
            accessLevel: {
              heading: 'Modify who can see the {{type}}',
              content: 'Apply to folder’s content',
              options: {
                admin: 'Admin',
                exec: 'Exec',
                crew: 'Crew',
                dtr: 'DTR'
              },
              success: '{{name}} Access Level updated to {{access}}',
              error: {
                server: 'Something went wrong. Please contact support if the problem persists.'
              }
            },
            merge: {
              order: {
                heading: 'Merged File Order',
                content: 'Click and drag files to set order'
              },
              name: {
                heading: 'Merged File Name',
                content: 'File Name'
              },
              error: {
                none: 'Please select at least two files to merge',
                name: 'Please provide a name for your file.',
                type: 'Only PDF files can be merged.',
                server: 'Communication error. Please contact support@croogloo.com if the problem persists.'
              },
              success: 'Files Successfully Merged'
            },
            unlink: {
              dropbox: 'Dropbox Unlinked',
              gDrive: 'Google Drive Unlinked',
              box: 'Box Unlinked'
            }
          },
          notifications: {
            status: {
              init: 'Initializing',
              fin: 'Finalizing',
              progress: 'Progress: {{progress}}%',
              download: 'Download',
              failed: 'Process failed',
              error: 'Server error',
              empty: 'Empty',
              upload: 'Upload Complete',
              success: 'Success'
            },
            watermark: {
              heading: 'Watermark to Print',
              complete: 'Generation Complete'
            },
            download: {
              heading: 'File Download',
              ready: 'Your Download is ready',
              pushTitle: 'Download Ready',
              pushSubtitle: 'Your file is ready to download'
            },
            upload: {
              heading: 'File Upload',
              progress: 'Upload in progress: {{progress}}%',
              complete: 'Upload Complete',
              failed: 'Upload Failed'
            },
            contact: {
              heading: 'Expired Contacts',
              view: 'Click to View',
              modal: {
                heading: 'Following Contact(s) are expired',
                remove: 'Remove from Distribution Lists',
                error: 'Server Error'
              }
            },
            vCard: {
              heading: 'Importing vCards...'
            }
          },
          import: {
            label: 'Import',
            vCard: {
              success: 'vCards successfully imported',
              label: 'DROP vCARD HERE',
              error: {
                fileType: 'Only vCard (*.vcf) files are accepted.',
                department: 'Please select a department',
                server: 'Error importing vCards.'
              },
              heading: 'vCard Information',
              departments: {
                label: 'Upload contacts to'
              },
              lists: {
                label: 'Preselect distribution lists (optional)',
                placeholder: 'Click to select distribution lists'
              }
            },
            google: {
              success: 'Google Contacts to be shown in Contacts shortly',
              error: 'Error importing google contacts. Please contact support if the problem persists.'
            }
          },
          subscription: {
            manage: {
              heading: 'Manage Subscription',
              contentReg: 'Current Plan: {{plan}}',
              placeholder: 'Select Desired Subscription Plan',
              note: 'Please note that processing your request can take up to 24 hours.',
              billing: {
                month: 'month',
                year: 'year'
              },
              finished: {
                trial: 'Your trial plan was successfully changed to {{newPlan}}.',
                reg: 'Your request is being processed.'
              },
              error: {
                empty: 'Please select a subscription plan.',
                server: 'Server error, please contact {{email}}'
              }
            },
            cancel: {
              error: {
                admin: 'Only an administrator can wrap the show.',
                trial: 'Wrapping is not offered in trial mode.',
                auto: 'Automatic wrapping is not currently offered for your production type.',
                cancelled: 'Your subscription has already been cancelled.',
                failed: 'Failed to wrap production',
                server: 'Something went wrong please contact support',
                zip: 'Failed to create ZIP file.'
              },
              notAvailable: {
                heading: 'Incompatible Subscription',
                content: 'Automatic wrapping is not enabled for your subscription. Please contact us (support@croogloo.com) if you\'re done and would like to archive or delete your production.'
              },
              confirm: {
                heading: 'Congratulations on wrapping your show!',
                content: 'What would you like to do with your data?',
                options: {
                  zip: 'Compress documents into a ZIP file',
                  cloud: 'Archive to the cloud ({{amount}}/yr)'
                }
              },
              success: {
                heading: 'Operation Complete',
                content: {
                  zip: 'Your ZIP file is being generated. We will let you know when it\'s done via the notification bell on the top right corner of your screen. Your subscription will expire at the end of the current billing cycle.',
                  cloud: 'Your subscription will be changed to Archive Mode at the end of the current billing cycle. Only you will retain access to your files and folders.'
                }
              }
            },
            expiredTrial: {
              heading: 'Expired Trial',
              content: 'The trial has expired. Please contact an administrator to continue using this production.'
            },
            endTrial: {
              heading: 'Confirm Subscription Plan',
              billingSame: 'Shipping address is the same as billing address',
              cardError: 'There was an error with the card. Please try again',
              shippingDetails: 'Shipping Details',
              billingDetails: 'Billing Details',
              emailError: 'Please enter an email address',
              success: {
                heading: 'Success',
                content: 'You have successfully subscribed to {{plan}}.'
              },
              cancel: {
                heading: 'Payment Cancelled'
              },
              failed: {
                heading: 'Error',
                content: 'Something went wrong processing your payment. You have not been charged. Please contact support for further assistance regarding this issue.'
              }
            }
          },
          production: {
            switch: {
              error: {
                none: 'No other accessible production',
                empty: 'Please select a production',
                denied: 'Access to {{community}} denied',
                server: 'Server error. Please contact support'
              },
              heading: 'Switch Production',
              content: 'What production environment would you like to switch to?',
              noAccess: 'You currently do not have access to {{production}}. Please choose another'
            },
            isTrial: {
              oneDay: 'TRIAL MODE - {{count}} DAY LEFT',
              other: 'TRIAL MODE - {{count}} DAYS LEFT'
            }
          },
          pdfViewer: {
            expiredLink: {
              heading: 'Expired Link',
              content: 'You will be redirected in a few seconds'
            },
            disclaimer: {
              heading: 'Disclaimer',
              content: 'Please note that the following document is only meant to be viewed in a browser window. It should not be downloaded and/or printed under any circumstances.'
            },
            viewer: {
              defaultTitle: 'File Viewer'
            }
          },
          nothingToSeeHere: {
            type: {
              draft: 'draft',
              list: 'list',
              group: 'group',
              contact: 'Contact',
              side: 'Side',
              script: 'Script'
            },
            title: 'Nothing to see here.',
            create: 'Create a {{entityType}} and I will display the goods.'
          }
        }
      },
      fr: {
        translation: {
          headerTitle: 'Ceci est Croogloo'
        }
      }
    },
    fallbackLng: 'en',
    debug: false
  });

export default i18n;
